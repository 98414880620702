import {
  EndRightDTO,
  RightLicensePlateChangeDTO,
  RightResponseDTO
} from '../../apis/rights/dto'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  MoovyButton,
  MoovyDialog,
  MoovyDialogConfirmation,
  MoovyForm
} from '..'

import { BackendDatetime } from '../../utils/datetimeUtils'
import FormEditVehicle from './formEditVehicle'
import { MoovyToast } from '../../utils/moovyToast'
import RightsAPI from '../../apis/rights/rightsAPI'
import useAuth from '../../hooks/useAuth'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import {
  DEFAULT_CACHE_TIME_MS,
  QUERY_CATALOGUES_BY_REF
} from '../../apis/moovyQueryKeys'
import { CataloguesAPI } from '../../apis/catalogues/cataloguesAPI'
import { MoovyAlert } from '../moovyAlert'

export type ModalEditVehicleProps = {
  open: boolean
  catalogueId: string
  rightId: string
  licensePlate: string
  costCenter?: string
  project?: string
  onTerminated: () => void
  onSubmit: (values: RightResponseDTO) => void
  onCancel: () => void
  showAccountingFields?: boolean
}

const ModalEditVehicle = ({
  open,
  rightId,
  catalogueId,
  licensePlate,
  costCenter,
  project,
  onTerminated,
  onSubmit,
  onCancel,
  showAccountingFields
}: ModalEditVehicleProps) => {
  const FORM_REFERENCE = 'formEditVehicle'
  const intl = useIntl()
  const { isPermission } = useAuth()
  const [confirmationTerminateRight, setConfirmationTerminateRight] =
    useState(false)
  const [endReason, setEndReason] = useState('')
  const [endReasonMissing, setEndReasonMissing] = useState(false)

  const queryCatalog = useQuery({
    queryKey: [QUERY_CATALOGUES_BY_REF, catalogueId],
    queryFn: () => CataloguesAPI.getCataloguesByRef(catalogueId),
    staleTime: DEFAULT_CACHE_TIME_MS,
    enabled: !!catalogueId
  })

  const isWebshopCatalogue = queryCatalog.data?.type === 'WEBSHOP'

  const changeRightMutation = useMutation({
    mutationFn: (dto: RightLicensePlateChangeDTO) =>
      RightsAPI.changeLicensePlate(rightId, dto),
    onSuccess: (response) => {
      MoovyToast.showToast(
        <FormattedMessage id="modalEditVehicle.actions.change" />
      )
      onSubmit(response.data)
    }
  })

  const updateRightHolder = (licensePlate: string) => {
    const dto: RightLicensePlateChangeDTO = {
      licensePlate: licensePlate ? licensePlate.trim().toLocaleUpperCase() : ''
    }

    if (dto.licensePlate) {
      changeRightMutation.mutate(dto)
    }
  }

  const terminateRightMutation = useMutation({
    mutationFn: (endRight: EndRightDTO) =>
      RightsAPI.endRight(rightId, endRight),
    onSuccess: (response) => {
      setConfirmationTerminateRight(false)
      setEndReason('')
      setEndReasonMissing(false)
      onTerminated()
      MoovyToast.showToast(
        <FormattedMessage id="modalEditVehicle.actions.rightEnded" />
      )
    }
  })

  const cancel = () => {
    changeRightMutation.reset()
    onCancel()
  }

  const cancelConfirmation = () => {
    setConfirmationTerminateRight(false)
    setEndReasonMissing(false)
  }

  return (
    <>
      <MoovyDialog
        open={open}
        onCancel={cancel}
        formReference={FORM_REFERENCE}
        mutation={changeRightMutation}
        errorTitleKey={'common.actions.update.error'}
        modalTitle={<FormattedMessage id="modalEditVehicle.title" />}
        submitButtonContent={<FormattedMessage id="common.buttons.update" />}
        disableSubmit={isWebshopCatalogue}
        leftSideButtons={
          isPermission('DELETE', 'RIGHTS')
            ? [
                <MoovyButton
                  color="error"
                  onClick={() => {
                    setConfirmationTerminateRight(true)
                  }}
                  variant="text"
                  key={'terminate'}
                  disabled={isWebshopCatalogue}
                >
                  <FormattedMessage id="common.buttons.terminate" />
                </MoovyButton>
              ]
            : undefined
        }
      >
        {isWebshopCatalogue && (
          <MoovyAlert
            title={intl.formatMessage({
              id: 'modalEditVehicle.webshopWarning'
            })}
            severity="warning"
            style={{ marginBottom: '1rem' }}
          />
        )}

        <FormEditVehicle
          formReference={FORM_REFERENCE}
          licensePlate={licensePlate}
          costCenter={costCenter}
          project={project}
          onSubmit={updateRightHolder}
          showAccountingFields={showAccountingFields}
        />
      </MoovyDialog>
      <MoovyDialogConfirmation
        open={confirmationTerminateRight}
        lang={{
          confirmButton: 'common.buttons.yes',
          error: 'modalEditVehicle.actions.error.end'
        }}
        header={intl.formatMessage({
          id: 'modalEditVehicle.modalRemove.header'
        })}
        content={
          <>
            <div style={{ marginBottom: '1em' }}>
              <FormattedMessage
                id="modalEditVehicle.modalRemove.content"
                values={{ licensePlate: licensePlate }}
              />
            </div>
            <MoovyForm.FormContainer>
              <MoovyForm.FormTextField
                name="endReason"
                data-testid="endReason"
                value={endReason}
                autoFocus
                multiline
                error={endReasonMissing}
                errorText={
                  endReasonMissing
                    ? intl.formatMessage({
                        id: 'common.form.errors.empty'
                      })
                    : ''
                }
                label={intl.formatMessage({
                  id: 'modalEditVehicle.modalRemove.content.endReason'
                })}
                placeholder={intl.formatMessage({
                  id: 'modalEditVehicle.modalRemove.content.endReason.placeholder'
                })}
                onChange={(evt) => {
                  setEndReason(evt.target.value)
                  setEndReasonMissing(false)
                }}
              />
            </MoovyForm.FormContainer>
          </>
        }
        onCancel={cancelConfirmation}
        onConfirm={() => {
          if (endReason) {
            terminateRightMutation.mutate({
              validityEnd: BackendDatetime.nowToUTC(),
              endReason
            })
          } else {
            setEndReasonMissing(true)
          }
        }}
        mutation={terminateRightMutation}
      />
    </>
  )
}

export default ModalEditVehicle
