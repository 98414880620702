import { UseMutationResult, UseQueryResult } from '@tanstack/react-query'

import { AxiosResponse } from 'axios'
import { IntlShape, useIntl } from 'react-intl'
import React from 'react'
import { MoovyAlert } from '../moovyAlert'

interface ValidationErrorDTO {
  code: string
  description: string
  field: string
}

const getValidationErrors = (
  intl: IntlShape,
  validationErrors: ValidationErrorDTO[]
) => {
  return validationErrors?.map((item) => {
    return intl.formatMessage(
      {
        id: `validation.code.${item.code}`,
        defaultMessage: intl.formatMessage({
          id: 'validation.code.generic'
        })
      },
      {
        field: intl.formatMessage({
          id: `validation.field.${item.field}`,
          defaultMessage: item.field
        })
      }
    )
  })
}

type ApiLangErrorBody = {
  response?: {
    data?: {
      validationErrors?: ValidationErrorDTO[]
      code?: string
    }
  }
}

export const getApiValidationErrors = (
  intl: IntlShape,
  error: ApiLangErrorBody
) => {
  if (
    error.response?.data?.validationErrors &&
    error.response.data.validationErrors.length > 0
  ) {
    return getValidationErrors(intl, error.response.data.validationErrors)
  }

  return null
}

export const getApiErrorCode = (intl: IntlShape, error: ApiLangErrorBody) => {
  if (error.response?.data?.code) {
    return intl.formatMessage({
      id: `api.error.code.${error.response?.data?.code}`,
      defaultMessage: intl.formatMessage({
        id: 'api.error.code.generic'
      })
    })
  }

  return null
}

type MoovyMutationErrorProps = {
  mutation?: UseMutationResult<AxiosResponse<any, any>, unknown, any, unknown>
  query?: UseQueryResult<any, any>
  errorTitleKey: string
  errorBodyKey?: string
}

const MoovyMutationError = ({
  mutation,
  query,
  errorTitleKey,
  errorBodyKey
}: MoovyMutationErrorProps) => {
  const intl = useIntl()

  const localizedErrorText = ({
    mutation,
    query,
    errorBodyKey,
    errorTitleKey
  }: MoovyMutationErrorProps) => {
    if (!mutation?.isError && !query?.isError) {
      return ''
    }

    const error = (mutation?.error || query?.error) as ApiLangErrorBody

    const localizedValidationErrors = getApiValidationErrors(intl, error)
    const localizedError = getApiErrorCode(intl, error)

    const localizedTitle =
      errorTitleKey && intl.formatMessage({ id: errorTitleKey })

    if (localizedValidationErrors) {
      return (
        <MoovyAlert title={localizedTitle}>
          <>
            {localizedValidationErrors.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </>
        </MoovyAlert>
      )
    } else if (localizedError) {
      return <MoovyAlert title={localizedError} />
    } else {
      return (
        <MoovyAlert title={localizedTitle}>
          {errorBodyKey ? intl.formatMessage({ id: errorBodyKey }) : null}
        </MoovyAlert>
      )
    }
  }

  return localizedErrorText({
    mutation,
    query,
    errorBodyKey,
    errorTitleKey
  })
}

export default MoovyMutationError
