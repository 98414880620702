import { CatalogueReportDTO, RightReportDTO } from './dto'

import { API_ROOT_URL } from '..'
import { client } from '../moovyAxiosClient'

const BASE_URL = `${API_ROOT_URL}/reports`

const getCataloguesReport = (
  customerIds: string[],
  validFrom: string,
  validUntil: string
) => {
  return client.post<CatalogueReportDTO>(`${BASE_URL}/catalogues`, {
    customerIds,
    validFrom,
    validUntil
  })
}

const getRightsReport = (
  customerIds: string[],
  validFrom: string,
  validUntil: string
) => {
  return client.post<RightReportDTO>(`${BASE_URL}/rights`, {
    customerIds,
    validFrom,
    validUntil
  })
}

export const ReportsAPI = {
  getCataloguesReport,
  getRightsReport
}
