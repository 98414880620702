{
  "common.yes": "kyllä",
  "common.no": "ei",
  "common.ok": "ok",

  "common.buttons.yes": "Kyllä",
  "common.buttons.add": "Lisää",
  "common.buttons.save": "Tallenna",
  "common.buttons.edit": "Muokkaa",
  "common.buttons.next": "Seuraava",
  "common.buttons.complete": "Valmis",
  "common.buttons.cancel": "Peruuta",
  "common.buttons.remove": "Poista",
  "common.buttons.update": "Päivitä",
  "common.buttons.terminate": "Irtisano",
  "common.buttons.back": "Takaisin",
  "common.buttons.clear": "Tyhjennä",

  "common.actions.error": "Tapahtui virhe",
  "common.actions.add.error": "Lisäys epäonnistui",
  "common.actions.update.error": "Päivitys epäonnistui",
  "common.actions.get.error": "Tietojen haku epäonnistui",
  "common.actions.remove.error": "Poisto epäonnistui",

  "common.form.errors.empty": "Kenttä ei voi olla tyhjä.",
  "common.form.errors.email": "Syötä kelvollinen sähköpostiosoite.",
  "common.form.errors.phoneNumber": "Syötä kelvollinen puhelinnumero maatunnuksella.",
  "common.form.errors.licensePlate": "Syötä kelvollinen ajoneuvon rekisteritunnus.",
  "common.form.errors.businessId": "Syötä kelvollinen Y-tunnus.",
  "common.form.errors.number": "Kenttään voi syöttää vain numeroita.",
  "common.form.errors.invalidDateTime": "Syötä kelvollinen päivämäärä sekä aika",

  "common.text.licensePlate": "Rekisteritunnus",
  "common.text.email": "Sähköposti",
  "common.text.project": "Projekti",
  "common.text.costCenter": "Kustannuspaikka",
  "common.text.validityStart": "Alkaa",
  "common.text.validityEnd": "Päättyy",

  "menu.invitations": "Kutsu asiakas",
  "menu.allPlaces": "Kohdelistaus",
  "menu.places": "Kohde & Luvat",
  "menu.customer": "Omistajan tiedot",
  "menu.reports": "Raportit",
  "menu.user.detail": "Omat tiedot",
  "menu.user.logout": "Kirjaudu ulos",
  "menu.user.fi": "Suomeksi",
  "menu.user.en": "Englanniksi",
  "menu.subHeader.moovyAdmin": "Moovy Admin",

  "appMenuTop.button.menu": "Valikko",
  "appMenuTop.button.closeMenu": "Sulje menu",

  "validation.code.NOT_EMPTY": "{field, plural, one {Kenttä ei voi olla tyhjä.} other {{field}-kenttä ei voi olla tyhjä.}}",
  "validation.code.MAX_LENGTH": "{field, plural, one {Merkkejä on liikaa.} other {{field}-kentässä on liikaa merkkejä.}}",
  "validation.code.MIN_LENGTH": "{field, plural, one {Syötetty merkkimäärä ei ole riittävä.} other {{field}-kentässä ei ole riittävästi merkkejä.}}",
  "validation.code.BUSINESS_ID_FORMAT": "{field, plural, one {Syötteen muoto on virheellinen.} other {{field}-kentän syöte on virheellisessä muodossa.}}",
  "validation.code.generic": "Tarkista syöttämäsi arvot.",
  "validation.field.name": "Nimi",
  "validation.field.streetAddress": "Osoite",
  "validation.field.city": "Kaupunki",
  "validation.field.postalCode": "Postinumero",
  "validation.field.businessId": "Y-tunnus",
  "validation.field.costCenter": "Kustannuspaikka",
  "validation.field.project": "Projekti",

  "sort.PLACES.NAME": "Nimi",
  "sort.PLACES.CITY": "Postitoimipaikka",
  "sort.PLACES.CAPACITY": "Ruutujen määrä",
  "sort.PLACES.CUSTOMER_ID": "Asiakas",
  "sort.INVITATIONS.NAME": "Nimi",
  "sort.INVITATIONS.EMAIL": "Sähköposti",
  "sort.INVITATIONS.CREATED_AT": "Kutsuttu",
  "sort.INVITATIONS.USED": "Rekisteröitynyt",
  "sort.ACTIVE_RIGHTS.INDIVIDUAL": "Nimi",
  "sort.ACTIVE_RIGHTS.VEHICLE": "Rekisteritunnus",
  "sort.ACTIVE_RIGHTS.VALIDITY_START": "Voimassa alkaen",
  "sort.ACTIVE_RIGHTS.VALIDITY_END": "Päättyy",
  "sort.CATALOGUES.CUSTOMER_ID": "Asiakas",
  "sort.CATALOGUES.CREATED_AT": "Luotu",
  "sort.CATALOGUES.UPDATED_AT": "Päivitetty",
  "sort.CATALOGUES.AMOUNT": "Määrä",

  "api.error.code.OFFERING_AMOUNT_EXCEEDED_ERROR": "Tarjooman pitää olla vähintään jaettujen lupien määrä.",
  "api.error.code.CUSTOMER_ALREADY_EXISTS": "Samanniminen asiakas on jo järjestelmässä.",
  "api.error.code.INVITATION_ALREADY_EXISTS": "Kutsu on jo olemassa samalle henkilölle.",
  "api.error.code.CATALOGUE_AMOUNT_EXCEEDED": "Lupamäärä liian suuri. Myönnettävien lupien määrä ei saa ylittää tuotteen lupien kokonaismäärää.",
  "api.error.code.CATALOGUE_AMOUNT_TOO_SMALL": "Lupamäärä liian pieni. Myönnettävien lupien määrä täytyy olla vähintään sama kuin jaettujen lupien määrä.",
  "api.error.code.CATALOGUE_DOES_NOT_ALLOW_VEHICLE_GRANTING": "Lupasopimus ei salli lupien myöntämistä ajoneuvolle.",
  "api.error.code.EMAIL_ALREADY_TAKEN": "Sähköpostiosoite on jo käytössä.",
  "api.error.code.TOO_MANY_CATALOGUES_IN_REPORT": "Raporttiin sisältyy liian monta lupasopimusta. Vaihda hakukriteereitä ja yritä uudelleen.",
  "api.error.code.TOO_MANY_RIGHTS_IN_REPORT": "Raporttiin sisältyy liian monta lupaa. Vaihda hakukriteereitä ja yritä uudelleen.",
  "api.error.code.RIGHT_HOLDER_EXCEEDS_CATALOGUE_AMOUNT": "Lupamäärä liian suuri. Myönnettävien lupien määrä ei saa ylittää lupasopimuksen lupien kokonaismäärää.",
  "api.error.code.generic": "Tapahtui virhe",

  "moovyErrorPage.oops": "Hups!",
  "moovyErrorPage.message": "Pahoittelut, sivua ei voi ladata. Tarkista osoite ja yritä ladata sivu uudestaan.",

  "components.modalSubPlace.actions.error.added": "Asiakaskohteen lisäys epäonnistui",
  "components.modalSubPlace.actions.added": "Asiakaskohteen lisäys onnistui",
  "components.modalSubPlace.title": "Lisää asiakaskohde",
  "components.modalSubPlace.button.add": "Lisää asiakaskohde",
  "components.tableSubPlaces.header.name": "Nimi",
  "components.tableSubPlaces.header.customer": "Asiakas",
  "components.tableSubPlaces.header.streetAddress": "Postiosoite",
  "components.tableSubPlaces.header.postalCode": "Postinumero",
  "components.tableSubPlaces.header.city": "Postitoimipaikka",
  "components.tableSubPlaces.header.capacity": "Ruutujen määrä",
  "components.tableSubPlaces.action.empty": "Kohteita ei ole",
  "components.tableSubPlaces.action.failed": "Kohteiden haku epäonnistui",

  "page.steps.bar.landlord.title": "Kohteen omistajan tiedot",
  "page.steps.bar.landlord.description": "Täytä omistajan tiedot",
  "page.steps.bar.place.title": "Kohteen tiedot",
  "page.steps.bar.place.description": "Täytä kohteen tarvittavat tiedot",
  "page.steps.landlord.title": "Kohteen omistajan tiedot",
  "page.steps.landlord.info": "Perusta Moovyn organisaatiosi, syöttämällä vaaditut tiedot. Organisaatio ja sen edustajat ovat vastuussa luotavasta pysäköintikohteesta.",
  "page.steps.landlord.actions.added": "Uusi yritys {name} lisätty.",
  "page.steps.place.title": "Kohteen tiedot",
  "page.steps.place.info": "Ole hyvä ja täytä kohteen perustiedot. Tiedot ovat pakollisia, sillä niitä käytetään Moovyssa esimerkiksi pysäköintilupien hallintaan. Voit myöhemmin muuttaa ja täydentää kohteen tietoja",
  "page.steps.place.actions.added": "Uusi kohde {name} lisätty.",

  "page.invitations.modalAddInvitation.landlord.title": "Kutsu asiakas",
  "page.invitations.modalAddInvitation.tenant.title": "Lisää ja kutsu hallinnoija",
  "page.invitations.invitation.actions.updated": "Kutsulinkki {name} päivitetty.",
  "page.invitations.formInvitation.label.firstName": "Etunimi",
  "page.invitations.formInvitation.label.lastName": "Sukunimi",
  "page.invitations.list.button.landlord.newInvitation": "Kutsu asiakas",
  "page.invitations.list.button.tenant.newInvitation": "Lisää ja kutsu hallinnoija",
  "page.invitations.list.button.remove": "Poista kutsu",
  "page.invitations.list.title": "Kutsu uusia asiakkaita",
  "page.invitations.list.actions.added": "Uusi kutsu {name} lisätty.",
  "page.invitations.list.actions.removed": "Kutsu {name} poistettu.",
  "page.invitations.list.table.header.name": "Nimi",
  "page.invitations.list.table.header.invited": "Kutsuttu",
  "page.invitations.list.table.header.registeredIn": "Rekisteröitynyt",
  "page.invitations.list.table.action.empty": "Kutsulinkkejä ei ole",
  "page.invitations.list.table.action.failed": "Kutsulinkkien haku epäonnistui",
  "page.invitations.list.card.header.registeredIn": "Rekisteröitynyt",
  "page.invitations.modalRemove.header": "Poista kutsu?",
  "page.invitations.modalRemove.content": "Haluatko varmasti poistaa kutsun käyttäjältä {name}?",

  "page.catalogues.modalAddRightHolder.title": "Lisää luvanhaltija",
  "page.catalogues.modalAddRightHolder.button.add": "Lisää luvanhaltija",
  "page.catalogues.modalAddRightHolder.steps.bar.email": "Sähköpostiosoite",
  "page.catalogues.modalAddRightHolder.steps.bar.rightHolder": "Luvanhaltijan tiedot",

  "page.catalogues.modalAddVehicleRightHolder.title": "Lisää ajoneuvo",

  "page.catalogues.tabCatalogue.formIndividualRightHolder.label.customerDetails": "Asiakkaan tiedot",
  "page.catalogues.tabCatalogue.formIndividualRightHolder.label.firstName": "Etunimi",
  "page.catalogues.tabCatalogue.formIndividualRightHolder.label.lastName": "Sukunimi",
  "page.catalogues.tabCatalogue.formIndividualRightHolder.label.phoneNumber": "Puhelinnumero",
  "page.catalogues.tabCatalogue.formIndividualRightHolder.label.productDetails": "Myönnettävä lupatuote",
  "page.catalogues.tabCatalogue.formIndividualRightHolder.label.licensePlateDetails": "Ajoneuvon rekisteritunnus",
  "page.catalogues.tabCatalogue.formIndividualRightHolder.label.validity": "Voimassaoloaika",
  "page.catalogues.tabCatalogue.modalFindRightHolder.info": "Kirjoita sähköpostiosoite luodaksesi uuden luvanhaltijan tai tarkistaaksesi löytyykö luvanhaltijalta jo Moovy-tili.",
  "page.catalogues.tabCatalogue.modalFindRightHolder.form.label.email": "Sähköpostiosoite",
  "page.catalogues.tabCatalogue.modalFindRightHolder.actions.added": "Uusi luvanhaltija {name} lisätty.",
  "page.catalogues.tabCatalogue.formVehicleRightHolder.label.vehicleDetails": "Ajoneuvon rekisteritunnus",
  "page.catalogues.tabCatalogue.formVehicleRightHolder.label.validity": "Luvan voimassaoloaika",

  "page.catalogues.tabCatalogue.title.rightHolder": "Luvanhaltijat",
  "page.catalogues.tabCatalogue.button.addIndividual": "Lisää luvanhaltija",
  "page.catalogues.tabCatalogue.button.addVehicle": "Lisää ajoneuvo",
  "page.catalogues.tabCatalogue.rightHolders.card.header.place": "Kohde",
  "page.catalogues.tabCatalogue.rightHolders.card.header.validUntil": "Päättyy",
  "page.catalogues.tabCatalogue.rightHolders.table.header.name": "Nimi",
  "page.catalogues.tabCatalogue.rightHolders.table.header.place": "Kohde",
  "page.catalogues.tabCatalogue.rightHolders.table.header.validFrom": "Voimassa alkaen",
  "page.catalogues.tabCatalogue.rightHolders.table.header.validUntil": "Päättyy",
  "page.catalogues.tabCatalogue.rightHolders.table.action.empty": "Luvanhaltijoita ei ole",
  "page.catalogues.tabCatalogue.rightHolders.table.action.failed": "Luvanhaltijoiden haku epäonnistui",

  "page.rightHolders.rightHolder.title": "Luvanhaltijan tiedot",
  "page.rightHolders.rightHolder.button.edit": "Muokkaa luvanhaltijaa",
  "page.rightHolders.rightHolder.modalEditRightHolder.title.individual": "Muokkaa luvanhaltijaa",
  "page.rightHolders.rightHolder.modalEditRightHolder.title.vehicle": "Muokkaa rekisteritunnusta",
  "page.rightHolders.rightHolder.modalEditRightHolder.actions.update": "Luvanhaltija {name} päivitetty.",
  "page.rightHolders.rightHolder.formEditRightHolder.label.firstName": "Etunimi",
  "page.rightHolders.rightHolder.formEditRightHolder.label.lastName": "Sukunimi",
  "page.rightHolders.rightHolder.formEditRightHolder.label.phoneNumber": "Puhelinnumero",
  "page.rightHolders.rightHolder.formEditRightHolder.info.product": "Käyttäjälle lisätään automaattisesti pysäköintilupa:",
  "page.rightHolders.rightHolder.tab.rightsAndVehicles.title": "Ajoneuvot ja luvat",
  "page.rightHolders.rightHolder.tab.vehicles.table.header.createdAt": "Luotu",
  "page.rightHolders.rightHolder.tab.vehicles.table.header.right": "Lupa",
  "page.rightHolders.rightHolder.tab.vehicles.table.header.place": "Kohde",
  "page.rightHolders.rightHolder.tab.vehicles.table.header.validFrom": "Voimassa alkaen",
  "page.rightHolders.rightHolder.tab.vehicles.table.header.validUntil": "Päättyy",
  "page.rightHolders.rightHolder.tab.vehicles.table.item.permanent": "Toistaiseksi",
  "page.rightHolders.rightHolder.tab.vehicles.table.action.empty": "Ajoneuvoja ei ole",
  "page.rightHolders.rightHolder.tab.vehicles.table.action.failed": "Ajoneuvojen haku epäonnistui.",
  "page.rightHolders.rightHolder.tab.vehicles.table.mobileCard.location": "Kohde:",
  "page.rightHolders.rightHolder.tab.vehicles.table.mobileCard.right": "Lupa:",
  "page.rightHolders.rightHolder.tab.vehicles.table.mobileCard.startRight": "Alkaen:",
  "page.rightHolders.rightHolder.tab.vehicles.table.mobileCard.endRight": "Päättyy:",

  "page.places.modalAddPlace.title": "Lisää kohde",
  "page.places.modalAddPlace.button.add": "Lisää kohde",
  "page.places.formPlace.contentTitle": "Kohteen tiedot",
  "page.places.formPlace.label.customer": "Asiakas",
  "page.places.formPlace.label.name": "Nimi",
  "page.places.formPlace.label.streetAddress": "Postiosoite",
  "page.places.formPlace.label.postalCode": "Postinumero",
  "page.places.formPlace.label.city": "Postitoimipaikka",
  "page.places.formPlace.label.capacity": "Ruutujen määrä",
  "page.places.list.button.newPlace": "Uusi kohde",
  "page.places.list.title": "Kohteet",
  "page.places.list.actions.added": "Uusi kohde {name} lisätty.",
  "page.places.list.table.header.name": "Nimi",
  "page.places.list.table.header.streetAddress": "Postiosoite",
  "page.places.list.table.header.postalCode": "Postinumero",
  "page.places.list.table.header.city": "Postitoimipaikka",
  "page.places.list.table.header.capacity": "Ruutujen määrä",
  "page.places.list.table.header.customer": "Asiakas",
  "page.places.list.table.action.empty": "Kohteita ei ole",
  "page.places.list.table.action.failed": "Kohteiden haku epäonnistui",

  "page.places.catalogueTabs.catalogue.header": "Luvat",
  "page.places.catalogueTabs.invitations.header": "Hallinnoijat",
  "page.catalogues.tabCatalogue.cataloguePlaceInfo.title": "Kohteen tiedot",
  "page.catalogues.tabCatalogue.cataloguePlaceInfo.name": "Nimi",
  "page.catalogues.tabCatalogue.cataloguePlaceInfo.street": "Osoite",
  "page.catalogues.tabCatalogue.cataloguePlaceInfo.capacity": "Ruudut",

  "page.catalogues.tabCatalogue.catalogueStatusInfo.title": "Tilannekuva",
  "page.catalogues.tabCatalogue.catalogueStatusInfo.grantAmount": "Jaettavien lupien määrä",
  "page.catalogues.tabCatalogue.catalogueStatusInfo.grantedAmount": "Lupia jaettu",
  "page.catalogues.tabCatalogue.catalogueStatusInfo.costCenter": "Kustannuspaikka",
  "page.catalogues.tabCatalogue.catalogueStatusInfo.project": "Projekti",

  "page.place.offeringsTab.header": "Luvat",
  "page.place.offeringsTab.infoText1": "Taulukosta näet kohteen lupien kokonaiskuvan.",
  "page.place.offeringsTab.table.empty": "Kohteella ei ole tarjoomaa",
  "page.place.offeringsTab.table.error": "Lupasopimusten lataamisessa tapahtui virhe.",
  "page.place.offeringsTab.table.overview": "Tilannekuva",
  "page.place.offeringsTab.table.timestamps": "Aikaleimat",
  "page.place.offeringsTab.table.product": "Tuote",
  "page.place.offeringsTab.table.catalogue.amount": "Lupia myönnetty:",
  "page.place.offeringsTab.table.offering.edit": "Muokkaa tarjoomaa",
  "page.place.actions.updated": "Kohde {name} päivitetty.",
  "page.place.modalEditPlace.title": "Muokkaa kohdetta",

  "page.placeRights.placeInformation.header": "Kohteen tiedot",
  "page.placeRights.placeInformation.button.edit": "Muokkaa kohdetta",
  "page.placeRights.placeInformation.address": "Osoite",
  "page.placeRights.placeInformation.capacity": "Ruutujen määrä",
  "page.placeRights.placeInformation.offeringAmount": "Lupia yhteensä",
  "page.placeRights.situationOverview.header": "Tilannekuva",
  "page.placeRights.situationOverview.totalAmount": "Asiakkaille myönnetyt luvat",
  "page.placeRights.situationOverview.parkingEnforcement": "Pysäköinninvalvonta",

  "page.customer.formSubPlace.title.location": "Kohteen tiedot",
  "page.customer.formSubPlace.title.space": "Myönnettävien pysäköintiruutujen määrä",
  "page.customer.formSubPlace.label.name": "Kohteen nimi",
  "page.customer.formSubPlace.label.spots": "Ruutujen määrä",
  "page.customer.formSubPlace.label.city": "Postitoimipaikka",
  "page.customer.formSubPlace.label.postalCode": "Postinumero",
  "page.customer.formSubPlace.label.streetAddress": "Postiosoite",

  "page.customers.formCustomer.contentTitle": "Asiakkaan tiedot",
  "page.customers.formCustomer.label.name": "Organisaation nimi",
  "page.customers.formCustomer.label.businessId": "Y-tunnus",
  "page.customers.formCustomer.label.streetAddress": "Postiosoite",
  "page.customers.formCustomer.label.postalCode": "Postinumero",
  "page.customers.formCustomer.label.city": "Postitoimipaikka",
  "page.customers.formCustomer.errors.businessId": "Kentän täytyy sisältää oikea Y-tunnus.",

  "page.customers.customersInformation.name": "Nimi",
  "page.customers.customersInformation.streetAddress": "Osoite",
  "page.customers.customersInformation.postalCodeAndCity": "Postinumero ja -toimipaikka",
  "page.customers.customersInformation.businessId": "Y-tunnus",
  "page.customers.customersInformation.customerNumber": "Asiakasnumero",

  "page.customers.tabAdministrators.infoText": "Lisää organisaation hallinnoija.",
  "page.customers.tabAdministrators.button.newAdminInvitation": "Lisää ja kutsu hallinnoija",
  "page.customers.tabAdministrators.actions.added": "Uusi kutsu {name} lisätty.",
  "page.customers.tabAdministrators.invitationsOngoing.link": "{count, plural, one {Yksi odottava kutsu.} other {{count} odottavaa kutsua.}}",
  "page.customers.tabAdministrators.tableAdministrators.header.name": "Nimi",
  "page.customers.tabAdministrators.tableAdministrators.header.registeredIn": "Rekisteröitynyt",
  "page.customers.tabAdministrators.tableAdministrators.button.remove": "Poista hallinnoija",
  "page.customers.tabAdministrators.tableAdministrators.action.empty": "Tilillä ei ole muita hallinnoijia.",
  "page.customers.tabAdministrators.tableAdministrators.action.failed": "Tilin hallinnoijien haku epäonnistui.",
  "page.customers.tabAdministrators.modalAdminInvitations.title": "Odottavat kutsut",
  "page.customers.tabAdministrators.modalRemove.header": "Poista kutsu?",
  "page.customers.tabAdministrators.modalRemove.content": "Haluatko poistaa kutsun {name}?",
  "page.customers.tabAdministrators.modalRemove.actions.removed": "Kutsu poistettu.",

  "page.customer.actions.updated": "Asiakas {name} päivitetty.",
  "page.customer.title": "Organisaation tiedot",
  "page.customer.button.edit": "Muokkaa asiakasta",
  "page.customer.modalEditCustomer.title": "Muokkaa asiakasta",
  "page.customer.customerTabs.locations.header": "Kohteet",
  "page.customer.customerTabs.admins.header": "Hallinnoijat",

  "page.offering.button.edit": "Muokkaa lupaa",
  "page.offering.cataloguesTab.table.error": "Katalogien lataamisessa tapahtui virhe",
  "page.offering.cataloguesTab.header": "Lupasopimukset",
  "page.offering.cataloguesTab.infoText1": "Taulukosta näet kohteen lupien kokonaiskuvan myönnettyjen lupien osalta.",
  "page.offering.cataloguesTab.infoText2": "Voit myöntää lupia uusille asiakkaille painikkeesta \"Luo lupasopimus\".",
  "page.offering.cataloguesTab.createCatalogue": "Luo lupasopimus",
  "page.offering.cataloguesTab.table.catalogue.edit": "Muokkaa sopimusta",
  "page.offering.cataloguesTab.table.name": "Nimi",
  "page.offering.cataloguesTab.table.customer": "Asiakas",
  "page.offering.cataloguesTab.table.overview": "Tilannekuva",
  "page.offering.cataloguesTab.table.timestamps": "Aikaleimat",
  "page.offering.cataloguesTab.table.empty": "Lupasopimuksia ei ole.",
  "page.offering.offeringInfo.title": "Luvan tiedot",
  "page.offering.offeringInfo.name": "Lupa",
  "page.offering.offeringInfo.location": "Kohde",
  "page.offering.offeringInfo.catalogues.amount": "Asiakkaille myönnetyt luvat",
  "page.offering.offeringInfo.maximumAmount": "Lupien maksimimäärä",
  "page.offering.offeringInfo.ignoreAmountLimits": "Rajoittamaton",

  "page.reports.title": "Raportit",
  "page.reports.type.radioGroup.title": "Valitse raporttityyppi",
  "page.reports.type.radioGroup.landlord": "Lupasopimukset",
  "page.reports.type.radioGroup.tenant": "Luvat",
  "page.reports.customer.title": "Valitse asiakas",
  "page.reports.customer.label": "Asiakas",
  "page.reports.customer.all": "Kaikki",
  "page.reports.range.title": "Valitse aikaväli",
  "page.reports.submit.button": "Lataa CSV-raportti",
  "page.reports.toast.error.emptyReport": "Raporttia ei löydy valituilla hakuehdoilla.",
  "page.reports.toast.error.csvFailed": "Raportin muodostus epäonnistui.",
  "page.reports.daterange.start": "Alku",
  "page.reports.daterange.end": "Loppu",

  "page.users.title": "Käyttäjä",
  "page.users.button.edit": "Muokkaa käyttäjää",
  "page.users.label.user": "Kirjautunut käyttäjä",
  "page.users.label.emailConfirmed": "Sähköposti vahvistettu",
  "page.users.modalChangePassword.title": "Vaihda salasana",
  "page.users.modalChangePassword.button.add": "Vaihda",
  "page.users.modalChangePassword.action.changed": "Salasana vaihdettu.",
  "page.users.formPassword.errors.password": "Uusi ja vahvistettu salasana pitää olla sama.",
  "page.users.formPassword.label.oldPassword": "Nykyinen salasana",
  "page.users.formPassword.label.newPassword": "Uusi salasana",
  "page.users.formPassword.label.confirmNewPassword": "Vahvista uusi salasana",

  "moovyParkingEnforcementStatus.no": "Ei valvontaa",
  "moovyParkingEnforcementStatus.yes": "Valvonnan piirissä",

  "timeStampItems.createdAt": "Luotu:",
  "timeStampItems.updatedAt": "Päivitetty:",

  "page.offering.modalCreateCatalogue.title": "Luo lupasopimus",
  "page.offering.modalCreateCatalogue.grantedTo": "Kenelle luvat myönnetään?",
  "page.offering.modalCreateCatalogue.grantedTo.customer": "Asiakkaalle",
  "page.offering.modalCreateCatalogue.grantedTo.self": "Omalle yritykselle",
  "page.offering.modalCreateCatalogue.customerLabel": "Asiakkaan tiedot",
  "page.offering.modalCreateCatalogue.customerName": "Organisaation nimi",
  "page.offering.modalCreateCatalogue.businessId": "Y-tunnus",
  "page.offering.modalCreateCatalogue.project": "Projekti",
  "page.offering.modalCreateCatalogue.costCenter": "Kustannuspaikka",
  "page.offering.modalCreateCatalogue.streetAddress": "Postiosoite",
  "page.offering.modalCreateCatalogue.postalCode": "Postinumero",
  "page.offering.modalCreateCatalogue.city": "Postitoimipaikka",
  "page.offering.modalCreateCatalogue.offering": "Myönnettävä lupatuote",
  "page.offering.modalCreateCatalogue.catalogueName": "Lupasopimuksen nimi",
  "page.offering.modalCreateCatalogue.name": "Nimi",
  "page.offering.modalCreateCatalogue.amount": "Määrä",
  "page.offering.modalCreateCatalogue.amount.minimum": "Arvon on oltava 0 tai suurempi",
  "page.offering.modalCreateCatalogue.allowVehicleGrant": "Salli luvan myöntäminen ajoneuvoille",
  "page.offering.modalCreateCatalogue.mutation.success": "Lupasopimuksen lisäys onnistui",
  "page.offering.modalCreateCatalogue.mutation.conflict": "Myönnettävien lupien määrä ei voi ylittää tuotteen kokonaislupamäärää.",

  "page.offering.modalEditOffering.title": "Muokkaa tarjoomaa",
  "page.offering.modalEditOffering.product": "Tuote",
  "page.offering.modalEditOffering.amountLabel": "Tarjooma yhteensä",
  "page.offering.modalEditOffering.amount.minimum": "Arvon on oltava 0 tai suurempi",
  "page.offering.modalEditOffering.mutation.success": "Tarjooman päivitys onnistui",

  "page.offering.modalEditCatalogue.title": "Muokkaa sopimusta",
  "page.offering.modalEditCatalogue.catalogueName": "Lupasopimuksen nimi",
  "page.offering.modalEditCatalogue.customer": "Asiakas",
  "page.offering.modalEditCatalogue.offering": "Myönnettävä lupatuote",
  "page.offering.modalEditCatalogue.amountLabel": "Myönnettävien lupien määrä",
  "page.offering.modalEditCatalogue.amount.minimum": "Arvon on oltava 0 tai suurempi",
  "page.offering.modalEditCatalogue.mutation.success": "Sopimuksen päivitys onnistui",

  "modalEditVehicle.title": "Muokkaa luvan ajoneuvoa",
  "modalEditVehicle.webshopWarning": "Verkkokauppasopimuksen lupaa ei voi muokata",
  "modalEditVehicle.actions.change": "Ajoneuvon rekisteritunnus vaihdettu",
  "modalEditVehicle.actions.rightEnded": "Lupa irtisanottu",
  "modalEditVehicle.actions.error.end": "Irtisanominen epäonnistui",
  "modalEditVehicle.modalRemove.header": "Irtisano lupa?",
  "modalEditVehicle.modalRemove.content": "Haluatko varmasti irtisanoa luvan ajoneuvolta {licensePlate}?",
  "modalEditVehicle.modalRemove.content.endReason": "Kommentti",
  "modalEditVehicle.modalRemove.content.endReason.placeholder": "Kommentti irtisanomisen syystä",

  "moovySortAndFilter.sortingCriteria": "Lajittele",

  "allocatedRights.available": "Myönnettävissä:",
  "allocatedRights.rightsGranted": "Lupia myönnetty:",
  "allocatedRights.rightsShared": "Lupia jaettu:",
  "allocatedRights.rightsTotal": "Lupia yhteensä:",

  "page.right.title": "Pysäköintilupa",
  "page.right.container.title": "Luvan tiedot",
  "page.right.start": "Alkoi",
  "page.right.end": "Päättyi",
  "page.right.licensePlate": "Rekisteritunnus",
  "page.right.place": "Kohde",
  "page.right.catalogue": "Lupasopimus",
  "page.right.individual": "Luvanhaltija",
  "page.right.costCenter": "Kustannuspaikka",
  "page.right.project": "Projekti",
  "page.right.endReason": "Irtisanomisen kommentti",
  "page.right.active": "Aktiivinen"
}
