import * as yup from 'yup'

import { FormattedMessage, useIntl } from 'react-intl'
import { Formik, FormikErrors } from 'formik'
import { MoovyDialog, MoovyForm } from '../../components'
import { Radio, RadioGroup } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { AxiosError } from 'axios'
import { CatalogueCreateDTO } from '../../apis/catalogues/dto'
import { CataloguesAPI } from '../../apis/catalogues/cataloguesAPI'
import { CustomerTypeEnum } from '../../apis/customers/dto'
import { FinnishBusinessIds } from 'finnish-business-ids'
import { MoovyToast } from '../../utils/moovyToast'
import { OfferingResponseDTO } from '../../apis/offering/dto'
import { QUERY_CATALOGUES_BY_OFFERING } from '../../apis/moovyQueryKeys'
import useCustomerData from '../../hooks/useCustomerData'

type ModalCreateCatalogueProps = {
  open: boolean
  onCancel: () => void
  onSubmit: () => void
  offering?: OfferingResponseDTO
}

type FormCatalogue = {
  amount: string
  catalogueName: string
  costCenter?: string
  project?: string
  customerName: string
  businessId: string
  streetAddress?: string
  postalCode?: string
  city?: string
  allowVehicleGrant?: boolean
}

const ModalCreateCatalogue: React.FC<ModalCreateCatalogueProps> = ({
  open,
  onCancel,
  onSubmit,
  offering
}) => {
  const FORM_REFERENCE = 'formCreateCatalogue'
  const intl = useIntl()
  const queryClient = useQueryClient()
  const [grantedTo, setGrantedTo] = useState<CustomerTypeEnum>('TENANT')

  const mutation = useMutation({
    mutationFn: (dto: CatalogueCreateDTO) => CataloguesAPI.createCatalogue(dto),
    onSuccess: () => {
      MoovyToast.showToast(
        <FormattedMessage id="page.offering.modalCreateCatalogue.mutation.success" />
      )
      onSubmit()
      queryClient.invalidateQueries({
        queryKey: [QUERY_CATALOGUES_BY_OFFERING, offering?.id]
      })
    }
  })
  const { customer } = useCustomerData()

  useEffect(() => {
    mutation.reset()
    setGrantedTo('TENANT')
  }, [open])

  if (!offering) {
    return null
  }

  const isWhitelist = offering.type === 'WHITELIST'

  // Handle conflict response as a amount exceeds offering error message, no localized error message from backend
  const error = mutation.error ? (mutation.error as AxiosError) : undefined
  const conflictErrorKey =
    error && error.response?.status === 409
      ? 'page.offering.modalCreateCatalogue.mutation.conflict'
      : undefined

  const nameSchema = {
    catalogueName: yup.string().required(
      intl.formatMessage({
        id: 'common.form.errors.empty'
      })
    )
  }

  const amountSchema = {
    amount: yup
      .number()
      .min(
        0,
        intl.formatMessage({
          id: 'page.offering.modalCreateCatalogue.amount.minimum'
        })
      )
      .required(
        intl.formatMessage({
          id: 'common.form.errors.empty'
        })
      )
      .typeError(
        intl.formatMessage({
          id: 'common.form.errors.number'
        })
      )
  }
  const landlordSchema = yup.object().shape({ ...nameSchema, ...amountSchema })
  const tenantSchema = yup.object().shape({
    ...nameSchema,
    ...amountSchema,
    customerName: yup.string().required(
      intl.formatMessage({
        id: 'common.form.errors.empty'
      })
    ),
    businessId: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'common.form.errors.empty'
        })
      )
      .test(
        'businessId',
        intl.formatMessage({
          id: 'common.form.errors.businessId'
        }),
        function (businessId?: string) {
          return (
            !!businessId && FinnishBusinessIds.isValidBusinessId(businessId)
          )
        }
      ),
    costCenter: yup.string(),
    project: yup.string(),
    streetAddress: yup.string(),
    postalCode: yup.string(),
    city: yup.string()
  })
  const whitelistSchema = yup.object().shape(nameSchema)

  const submitHandler = (values: FormCatalogue) => {
    const {
      catalogueName,
      amount,
      customerName,
      businessId,
      costCenter,
      project,
      streetAddress,
      postalCode,
      city,
      allowVehicleGrant
    } = values

    const dto: CatalogueCreateDTO = {
      name: catalogueName,
      costCenter: costCenter?.trim() || undefined,
      project: project?.trim() || undefined,
      amount: Number.parseInt(amount),
      customerId: customer.id,
      offeringId: offering.id,
      allowVehicleGrant: allowVehicleGrant
    }
    if (!isWhitelist && grantedTo === 'TENANT') {
      dto.createTenant = {
        type: 'TENANT',
        parentId: customer.id,
        name: customerName,
        businessId: businessId,
        streetAddress:
          !!streetAddress && streetAddress.trim().length > 0
            ? streetAddress
            : undefined,
        postalCode:
          !!postalCode && postalCode.trim().length > 0 ? postalCode : undefined,
        city: !!city && city.trim().length > 0 ? city : undefined
      }
      dto.customerId = undefined
    }
    mutation.mutate(dto)
  }

  const OwnerDetail = () => {
    return (
      <RadioGroup
        value={grantedTo}
        onChange={(event) =>
          setGrantedTo(event.target.value as CustomerTypeEnum)
        }
      >
        <MoovyForm.FormLabel sx={{ fontWeight: 'bold' }} focused={false}>
          <FormattedMessage id="page.offering.modalCreateCatalogue.grantedTo" />
        </MoovyForm.FormLabel>
        <MoovyForm.FormRadio
          control={<Radio />}
          label={
            <FormattedMessage id="page.offering.modalCreateCatalogue.grantedTo.customer" />
          }
          value={'TENANT'}
        />
        <MoovyForm.FormRadio
          control={<Radio />}
          label={
            <FormattedMessage id="page.offering.modalCreateCatalogue.grantedTo.self" />
          }
          value={'LANDLORD'}
        />
      </RadioGroup>
    )
  }

  const TenantDetail = ({
    values,
    handleChange,
    setFieldValue,
    errors
  }: {
    values: FormCatalogue
    handleChange: (e: React.ChangeEvent<any>) => void
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
    errors: FormikErrors<FormCatalogue>
  }) => {
    return (
      <>
        <MoovyForm.FormLabel sx={{ fontWeight: 'bold' }} focused={false}>
          <FormattedMessage id="page.offering.modalCreateCatalogue.customerLabel" />
        </MoovyForm.FormLabel>
        <MoovyForm.FormBoxRow flexDirection={'column'}>
          <MoovyForm.FormTextField
            name="customerName"
            data-testid="customerName"
            value={values.customerName}
            label={intl.formatMessage({
              id: 'page.offering.modalCreateCatalogue.customerName'
            })}
            placeholder={intl.formatMessage({
              id: 'page.offering.modalCreateCatalogue.customerName'
            })}
            onChange={handleChange}
            error={!!errors.customerName}
            errorText={errors.customerName}
          />
          <MoovyForm.FormTextField
            name="businessId"
            data-testid="businessId"
            value={values.businessId}
            label={intl.formatMessage({
              id: 'page.offering.modalCreateCatalogue.businessId'
            })}
            placeholder={intl.formatMessage({
              id: 'page.offering.modalCreateCatalogue.businessId'
            })}
            onChange={handleChange}
            error={!!errors.businessId}
            errorText={errors.businessId}
          />
        </MoovyForm.FormBoxRow>
        <MoovyForm.FormBoxRow flexDirection={'column'}>
          <MoovyForm.FormTextField
            name="costCenter"
            data-testid="costCenter"
            value={values.costCenter}
            label={intl.formatMessage({
              id: 'page.offering.modalCreateCatalogue.costCenter'
            })}
            placeholder={intl.formatMessage({
              id: 'page.offering.modalCreateCatalogue.costCenter'
            })}
            onChange={handleChange}
            error={!!errors.costCenter}
            errorText={errors.costCenter}
          />
          <MoovyForm.FormTextField
            name="project"
            data-testid="project"
            value={values.project}
            label={intl.formatMessage({
              id: 'page.offering.modalCreateCatalogue.project'
            })}
            placeholder={intl.formatMessage({
              id: 'page.offering.modalCreateCatalogue.project'
            })}
            onChange={handleChange}
            error={!!errors.project}
            errorText={errors.project}
          />
        </MoovyForm.FormBoxRow>
        <MoovyForm.FormBoxRow flexDirection={'column'}>
          <MoovyForm.FormTextField
            name="streetAddress"
            data-testid="streetAddress"
            value={values.streetAddress}
            label={intl.formatMessage({
              id: 'page.offering.modalCreateCatalogue.streetAddress'
            })}
            placeholder={intl.formatMessage({
              id: 'page.offering.modalCreateCatalogue.streetAddress'
            })}
            onChange={handleChange}
            error={!!errors.streetAddress}
            errorText={errors.streetAddress}
          />
          <MoovyForm.FormTextField
            name="postalCode"
            data-testid="postalCode"
            value={values.postalCode}
            label={intl.formatMessage({
              id: 'page.offering.modalCreateCatalogue.postalCode'
            })}
            placeholder={intl.formatMessage({
              id: 'page.offering.modalCreateCatalogue.postalCode'
            })}
            onChange={handleChange}
            error={!!errors.postalCode}
            errorText={errors.postalCode}
          />
          <MoovyForm.FormTextField
            name="city"
            data-testid="city"
            value={values.city}
            label={intl.formatMessage({
              id: 'page.offering.modalCreateCatalogue.city'
            })}
            placeholder={intl.formatMessage({
              id: 'page.offering.modalCreateCatalogue.city'
            })}
            onChange={handleChange}
            error={!!errors.city}
            errorText={errors.city}
          />
        </MoovyForm.FormBoxRow>
        <MoovyForm.FormLabel style={{ fontWeight: 'bold' }} focused={false}>
          <FormattedMessage id="page.offering.modalCreateCatalogue.offering" />
          {' ' + offering.name ?? ''}
        </MoovyForm.FormLabel>
        <MoovyForm.FormSwitch
          checked={values.allowVehicleGrant}
          name="allowVehicleGrant"
          label={intl.formatMessage({
            id: 'page.offering.modalCreateCatalogue.allowVehicleGrant'
          })}
          style={{ fontWeight: 'bold' }}
          onChange={(event) =>
            setFieldValue('allowVehicleGrant', event.target.checked)
          }
        />
      </>
    )
  }

  return (
    <MoovyDialog
      open={open}
      modalTitle={
        <FormattedMessage id="page.offering.modalCreateCatalogue.title" />
      }
      submitButtonContent={<FormattedMessage id="common.buttons.save" />}
      onCancel={onCancel}
      mutation={mutation}
      formReference={FORM_REFERENCE}
      errorTitleKey={conflictErrorKey}
    >
      <Formik
        enableReinitialize={true}
        validateOnChange={false}
        initialValues={{
          amount: '',
          catalogueName: '',
          customerName: '',
          businessId: '',
          streetAddress: '',
          postalCode: '',
          city: '',
          costCenter: '',
          project: ''
        }}
        validationSchema={
          isWhitelist
            ? whitelistSchema
            : grantedTo === 'LANDLORD'
            ? landlordSchema
            : tenantSchema
        }
        onSubmit={(values) => {
          submitHandler(values)
        }}
      >
        {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
          <form
            onSubmit={handleSubmit}
            id={FORM_REFERENCE}
            style={{ padding: '0 1rem' }}
          >
            <MoovyForm.FormContainer>
              <MoovyForm.FormLabel
                style={{ fontWeight: 'bold' }}
                focused={false}
              >
                <FormattedMessage id="page.offering.modalCreateCatalogue.catalogueName" />
              </MoovyForm.FormLabel>
              <MoovyForm.FormTextField
                name="catalogueName"
                value={values.catalogueName}
                onChange={handleChange}
                label={
                  <FormattedMessage id="page.offering.modalCreateCatalogue.catalogueName" />
                }
                error={!!errors.catalogueName}
                errorText={errors.catalogueName}
              />
              {!isWhitelist && (
                <>
                  <OwnerDetail />
                  {grantedTo === 'TENANT' && (
                    <TenantDetail
                      values={values}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      errors={errors}
                    />
                  )}
                  {!isWhitelist && (
                    <MoovyForm.FormTextField
                      name="amount"
                      value={values.amount}
                      onChange={handleChange}
                      label={
                        <FormattedMessage id="page.offering.modalCreateCatalogue.amount" />
                      }
                      error={!!errors.amount}
                      errorText={errors.amount}
                    />
                  )}
                </>
              )}
            </MoovyForm.FormContainer>
          </form>
        )}
      </Formik>
    </MoovyDialog>
  )
}

export default ModalCreateCatalogue
