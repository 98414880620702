import * as yup from 'yup'

import { Box, useMediaQuery, useTheme } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useEffect, useState } from 'react'
import {
  isValidLicensePlate,
  isValidPhoneNumber
} from '../../../../utils/validator'

import { Formik } from 'formik'
import { MoovyConstantNames } from '../../../../utils/globalNames'
import { MoovyForm } from '../../../../components'
import { OfferingNameIdDTO } from '../../../../apis/offering/dto'
import isEmail from 'validator/lib/isEmail'
import { DateTimePicker } from '@mui/x-date-pickers-pro'
import { DateTime } from 'luxon'

export interface DefaultRightHolderValues {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  licensePlate: string
  catalogueId: string
  validityStart: DateTime
  validityEnd?: DateTime | null
  costCenter?: string
  project?: string
}

type SubmitFormCallback = (values: DefaultRightHolderValues) => void
export type FormIndividualRightHolderProps = {
  disableCustomerData: boolean
  formReference: string
  catalogueId: string
  offering: OfferingNameIdDTO | undefined
  onSubmit: SubmitFormCallback
  defaultValues?: DefaultRightHolderValues
}

const FormIndividualRightHolder = (props: FormIndividualRightHolderProps) => {
  const intl = useIntl()
  const offeringName = props.offering?.name || ''

  const getFormDefaultValues = () => {
    return {
      firstName: props.defaultValues?.firstName || '',
      lastName: props.defaultValues?.lastName || '',
      email: props.defaultValues?.email || '',
      phoneNumber:
        props.defaultValues?.phoneNumber ||
        MoovyConstantNames.PHONE_FI_COUNTRY_CODE,
      licensePlate: props.defaultValues?.licensePlate || '',
      catalogueId: props.catalogueId || '',
      validityStart: props.defaultValues?.validityStart || DateTime.local(),
      validityEnd: props.defaultValues?.validityEnd || null,
      costCenter: props.defaultValues?.costCenter || '',
      project: props.defaultValues?.project || ''
    }
  }

  const [formikValidateOnChange, setFormikValidateOnChange] = useState(false)
  const [defaultValues, setDefaultValues] = useState<DefaultRightHolderValues>(
    getFormDefaultValues()
  )

  useEffect(() => {
    setDefaultValues(getFormDefaultValues())
  }, [
    props.defaultValues?.firstName,
    props.defaultValues?.lastName,
    props.defaultValues?.email,
    props.defaultValues?.phoneNumber,
    props.defaultValues?.licensePlate,
    props.offering?.id
  ])

  const schemaValidator = () => {
    return yup.object().shape({
      firstName: yup
        .string()
        .trim()
        .required(intl.formatMessage({ id: 'common.form.errors.empty' })),
      lastName: yup
        .string()
        .trim()
        .required(intl.formatMessage({ id: 'common.form.errors.empty' })),
      email: yup
        .string()
        .test(
          'email',
          intl.formatMessage({ id: 'common.form.errors.email' }),
          function (email?: string) {
            return !!email && isEmail(email)
          }
        ),
      phoneNumber: yup
        .string()
        .trim()
        .test(
          'phoneNumber',
          intl.formatMessage({
            id: 'common.form.errors.phoneNumber'
          }),
          function (phoneNumber?: string) {
            return isValidPhoneNumber(phoneNumber)
          }
        ),
      licensePlate: yup
        .string()
        .trim()
        .test(
          'licensePlate',
          intl.formatMessage({
            id: 'common.form.errors.licensePlate'
          }),
          function (licensePlate?: string) {
            return isValidLicensePlate(licensePlate)
          }
        ),
      validityStart: yup
        .date()
        .required(
          intl.formatMessage({ id: 'common.form.errors.invalidDateTime' })
        )
        .typeError(
          intl.formatMessage({
            id: 'common.form.errors.invalidDateTime'
          })
        ),
      validityEnd: yup
        .date()
        .nullable()
        .typeError(
          intl.formatMessage({
            id: 'common.form.errors.invalidDateTime'
          })
        ),
      costCenter: yup.string().max(255),
      project: yup.string().max(255)
    })
  }

  const onSubmitFormValues = (values: DefaultRightHolderValues) => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      licensePlate,
      catalogueId,
      validityStart,
      validityEnd,
      costCenter,
      project
    } = values
    props.onSubmit({
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.trim(),
      phoneNumber: phoneNumber.trim(),
      licensePlate: licensePlate.trim().toLocaleUpperCase(),
      catalogueId,
      validityStart,
      validityEnd,
      costCenter: costCenter?.trim() || undefined,
      project: project?.trim() || undefined
    })
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <Formik
        validate={() => setFormikValidateOnChange(true)}
        validationSchema={schemaValidator()}
        enableReinitialize={true}
        initialValues={defaultValues}
        validateOnChange={formikValidateOnChange}
        onSubmit={(values) => {
          onSubmitFormValues(values)
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} id={props.formReference}>
            <MoovyForm.FormContainer>
              <MoovyForm.FormTitle
                title={
                  <FormattedMessage id="page.catalogues.tabCatalogue.formIndividualRightHolder.label.customerDetails" />
                }
              />
              <MoovyForm.FormBoxRow flexDirection={'column'}>
                <MoovyForm.FormTextField
                  name="firstName"
                  data-testid="firstName"
                  value={values.firstName}
                  label={intl.formatMessage({
                    id: 'page.catalogues.tabCatalogue.formIndividualRightHolder.label.firstName'
                  })}
                  placeholder={intl.formatMessage({
                    id: 'page.catalogues.tabCatalogue.formIndividualRightHolder.label.firstName'
                  })}
                  onChange={handleChange}
                  disabled={props.disableCustomerData}
                  autoFocus={!props.disableCustomerData}
                  error={!!errors.firstName}
                  errorText={errors.firstName}
                />
                <MoovyForm.FormTextField
                  name="lastName"
                  data-testid="lastName"
                  value={values.lastName}
                  label={intl.formatMessage({
                    id: 'page.catalogues.tabCatalogue.formIndividualRightHolder.label.lastName'
                  })}
                  placeholder={intl.formatMessage({
                    id: 'page.catalogues.tabCatalogue.formIndividualRightHolder.label.lastName'
                  })}
                  onChange={handleChange}
                  disabled={props.disableCustomerData}
                  error={!!errors.lastName}
                  errorText={errors.lastName}
                />
              </MoovyForm.FormBoxRow>
              <MoovyForm.FormBoxRow flexDirection={'column'}>
                <MoovyForm.FormTextField
                  name="email"
                  data-testid="email"
                  value={values.email}
                  label={intl.formatMessage({
                    id: 'common.text.email'
                  })}
                  placeholder={intl.formatMessage({
                    id: 'common.text.email'
                  })}
                  onChange={handleChange}
                  disabled={props.disableCustomerData}
                  error={!!errors.email}
                  errorText={errors.email}
                />
                <MoovyForm.FormTextField
                  name="phoneNumber"
                  data-testid="phoneNumber"
                  value={values.phoneNumber}
                  label={intl.formatMessage({
                    id: 'page.catalogues.tabCatalogue.formIndividualRightHolder.label.phoneNumber'
                  })}
                  placeholder={intl.formatMessage({
                    id: 'page.catalogues.tabCatalogue.formIndividualRightHolder.label.phoneNumber'
                  })}
                  onChange={handleChange}
                  disabled={props.disableCustomerData}
                  error={!!errors.phoneNumber}
                  errorText={errors.phoneNumber}
                />
              </MoovyForm.FormBoxRow>
              <MoovyForm.FormTitle
                title={
                  <FormattedMessage id="page.catalogues.tabCatalogue.formIndividualRightHolder.label.productDetails" />
                }
              />
              <MoovyForm.FormSwitch
                checked={true}
                label={offeringName}
                style={{ fontWeight: 'bold' }}
                disabled
              />
              <MoovyForm.FormBoxRow flexDirection={'column'}>
                <MoovyForm.FormTextField
                  name="costCenter"
                  data-testid="costCenter"
                  value={values.costCenter}
                  label={intl.formatMessage({
                    id: 'common.text.costCenter'
                  })}
                  placeholder={intl.formatMessage({
                    id: 'common.text.costCenter'
                  })}
                  onChange={handleChange}
                  autoFocus={props.disableCustomerData}
                  error={!!errors.costCenter}
                  errorText={errors.costCenter}
                />
                <MoovyForm.FormTextField
                  name="project"
                  data-testid="project"
                  value={values.project}
                  label={intl.formatMessage({
                    id: 'common.text.project'
                  })}
                  placeholder={intl.formatMessage({
                    id: 'common.text.project'
                  })}
                  onChange={handleChange}
                  error={!!errors.project}
                  errorText={errors.project}
                />
              </MoovyForm.FormBoxRow>
              <MoovyForm.FormTitle
                title={
                  <FormattedMessage id="page.catalogues.tabCatalogue.formIndividualRightHolder.label.licensePlateDetails" />
                }
              />
              <Box
                display="flex"
                gap={2}
                flex={2}
                pr={1}
                width={isMobile ? '100%' : '50%'}
                flexDirection={{ xs: 'column', md: 'row' }}
              >
                <MoovyForm.FormTextField
                  name="licensePlate"
                  data-testid="licensePlate"
                  value={values.licensePlate}
                  label={intl.formatMessage({
                    id: 'common.text.licensePlate'
                  })}
                  placeholder={intl.formatMessage({
                    id: 'common.text.licensePlate'
                  })}
                  onChange={handleChange}
                  error={!!errors.licensePlate}
                  errorText={errors.licensePlate}
                />
              </Box>
              <MoovyForm.FormTitle
                title={
                  <FormattedMessage id="page.catalogues.tabCatalogue.formIndividualRightHolder.label.validity" />
                }
              />
              <MoovyForm.FormBoxRow flexDirection={'column'}>
                <DateTimePicker
                  name="validityStart"
                  data-testid="validityStart"
                  value={values.validityStart}
                  disablePast
                  onChange={(newValue) =>
                    setFieldValue('validityStart', newValue)
                  }
                  label={intl.formatMessage({
                    id: 'common.text.validityStart'
                  })}
                  slotProps={{
                    textField: {
                      helperText: errors.validityStart
                        ? '' + errors?.validityStart
                        : null,
                      error: !!errors.validityStart
                    }
                  }}
                />
                <DateTimePicker
                  name="validityEnd"
                  data-testid="validityEnd"
                  value={values.validityEnd}
                  onChange={(newValue) =>
                    setFieldValue('validityEnd', newValue)
                  }
                  label={intl.formatMessage({
                    id: 'common.text.validityEnd'
                  })}
                  slotProps={{
                    textField: {
                      helperText: errors.validityEnd
                        ? '' + errors?.validityEnd
                        : null,
                      error: !!errors.validityEnd
                    }
                  }}
                />
              </MoovyForm.FormBoxRow>
            </MoovyForm.FormContainer>
          </form>
        )}
      </Formik>
    </>
  )
}

export default FormIndividualRightHolder
