import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useParams } from 'react-router-dom'
import {
  DEFAULT_CACHE_TIME_MS,
  QUERY_RIGHT_GET_BY_REF
} from '../../apis/moovyQueryKeys'
import rightsAPI from '../../apis/rights/rightsAPI'
import {
  MoovyContainer,
  MoovyContainerContent,
  MoovyContainerHeader,
  MoovyContainerSubHeader,
  MoovyDateTime,
  MoovyListLabelValue,
  MoovyQueryHandler,
  MoovyScrollToTop
} from '../../components'
import { Grid } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'

const Right = () => {
  const { ref } = useParams()
  const intl = useIntl()
  if (!ref) {
    return null
  }

  const query = useQuery({
    queryKey: [QUERY_RIGHT_GET_BY_REF, ref],
    queryFn: () => rightsAPI.getRightByRef(ref),
    enabled: !!ref,
    staleTime: DEFAULT_CACHE_TIME_MS
  })
  const right = query.data

  const data = [
    {
      label: intl.formatMessage({
        id: 'page.right.start'
      }),
      value: <MoovyDateTime utcDateTime={right?.validityStart} />
    },
    {
      label: intl.formatMessage({
        id: 'page.right.end'
      }),
      value: right?.validityEnd ? (
        <MoovyDateTime utcDateTime={right?.validityEnd} />
      ) : null
    },
    {
      label: intl.formatMessage({
        id: 'page.right.licensePlate'
      }),
      value: right?.vehicle?.licensePlate
    },
    {
      label: intl.formatMessage({
        id: 'page.right.place'
      }),
      value: right?.place?.name
    },
    {
      label: intl.formatMessage({
        id: 'page.right.catalogue'
      }),
      value: right?.catalogue?.name
    },
    {
      label: intl.formatMessage({
        id: 'page.right.individual'
      }),
      value: right?.individual?.email
    },
    {
      label: intl.formatMessage({
        id: 'page.right.costCenter'
      }),
      value: right?.costCenter
    },
    {
      label: intl.formatMessage({
        id: 'page.right.project'
      }),
      value: right?.project
    },
    {
      label: intl.formatMessage({
        id: 'page.right.endReason'
      }),
      value: right?.endReason
    },
    {
      label: intl.formatMessage({
        id: 'page.right.active'
      }),
      value: intl.formatMessage({
        id: right?.active ? 'common.yes' : 'common.no'
      })
    }
  ].filter((item) => item.value)

  return (
    <MoovyQueryHandler query={query} rootPageIfNotFoundError={false}>
      <MoovyScrollToTop />
      <Grid container spacing={2} direction="row">
        <Grid item xs={12}>
          <MoovyContainer>
            <MoovyContainerHeader>
              <FormattedMessage id="page.right.title" />
            </MoovyContainerHeader>
            <MoovyContainerSubHeader
              title={<FormattedMessage id="page.right.container.title" />}
              divider
            />
            <MoovyContainerContent>
              <MoovyListLabelValue data={data} />
            </MoovyContainerContent>
          </MoovyContainer>
        </Grid>
      </Grid>
    </MoovyQueryHandler>
  )
}

export default Right
