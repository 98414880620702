import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'
import { MoovyErrorPage, MoovyProgress } from '..'
import {
  QUERY_AUTH_GET_SESSION,
  QUERY_CATALOGUES_FOR_LOGIN,
  QUERY_LANDLORDS_FOR_LOGIN,
  QUERY_PLACES_FOR_LOGIN
} from '../../apis/moovyQueryKeys'
import React, { useEffect } from 'react'

import { Box } from '@mui/material'
import { CataloguesAPI } from '../../apis/catalogues/cataloguesAPI'
import CustomersAPI from '../../apis/customers/customersAPI'
import LoginAPI from '../../apis/authenticate/loginAPI'
import { appRoutes } from '../../app/permissions'
import useAuth from '../../hooks/useAuth'
import useCustomerData from '../../hooks/useCustomerData'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'

const MoovyAuthenticator = ({ children }: { children?: React.ReactNode }) => {
  const { user } = useAuthenticator((context) => [context.user])
  const [searchParams] = useSearchParams()
  const signUp = searchParams.get('signUp')

  const {
    isAuthenticated,
    setAuthentication,
    admin,
    userGroups,
    customerType
  } = useAuth()
  const {
    setCustomerFromArray,
    customer,
    setPlaceFromArray,
    setPlaceFromCatalogue,
    setInitialized
  } = useCustomerData()
  const navigate = useNavigate()

  const queryCatalogues = useQuery({
    queryKey: [QUERY_CATALOGUES_FOR_LOGIN, customer.id],
    queryFn: () => CataloguesAPI.getCataloguesByCustomer(customer.id),
    enabled: false
  })

  const queryPlaces = useQuery({
    queryKey: [QUERY_PLACES_FOR_LOGIN],
    queryFn: () => CustomersAPI.getPlacesByCustomer(admin, customer?.id),
    enabled: false
  })

  const queryCustomers = useQuery({
    queryKey: [QUERY_LANDLORDS_FOR_LOGIN],
    queryFn: () =>
      admin
        ? CustomersAPI.getLandlords(true, { limit: 10 })
        : CustomersAPI.getCustomers(false, {
            limit: 10,
            sort: 'TYPE',
            direction: 'ASC'
          }),
    enabled: false
  })

  const sessionQuery = useQuery({
    queryKey: [QUERY_AUTH_GET_SESSION],
    queryFn: () => LoginAPI.getAuthSession(),
    enabled: false
  })

  useEffect(() => {
    if (queryCatalogues.isSuccess) {
      const response = queryCatalogues.data
      if (
        response &&
        response.length > 0 &&
        response[0]?.place?.id &&
        response[0]?.place?.name
      ) {
        setPlaceFromCatalogue(response[0])
      }
    }
  }, [queryCatalogues.isSuccess])

  useEffect(() => {
    if (queryPlaces.isSuccess) {
      const response = queryPlaces.data
      if (
        response &&
        response.length > 0 &&
        response[0]?.id &&
        response[0]?.name
      ) {
        setPlaceFromArray(response)
      } else {
        setInitialized(true)
        navigate(appRoutes.LOGIN_STEPS, { replace: true })
      }
    }
  }, [queryPlaces.isSuccess])

  useEffect(() => {
    if (queryCustomers.isSuccess) {
      const response = queryCustomers.data
      if (
        response.data &&
        response.data?.length > 0 &&
        response.data[0]?.id &&
        response.data[0]?.name
      ) {
        setCustomerFromArray(response.data, true)
      } else {
        setInitialized(true)
        navigate(appRoutes.LOGIN_STEPS, { replace: true })
      }
    }
  }, [queryCustomers.isSuccess])

  useEffect(() => {
    if (sessionQuery.isSuccess) {
      const jwtToken = LoginAPI.getJWTToken(sessionQuery.data)
      if (jwtToken) {
        setAuthentication({
          userGroups: LoginAPI.getUserGroups(sessionQuery.data),
          token: jwtToken
        })
        LoginAPI.setTokenToInterceptor(jwtToken)
      }
    }
  }, [sessionQuery.isSuccess])

  const waitingForSession = sessionQuery.isPending
  const waitingForCustomers = queryCustomers.isPending
  const waitingForPlaces =
    customerType === 'T'
      ? customer?.id && queryCatalogues.isPending
      : customer?.id && queryPlaces.isPending
  const waitingForGroups = userGroups === undefined

  // Login has happened, fetch session data to Redux state and Session storage
  useEffect(() => {
    user?.username && sessionQuery.refetch()
  }, [user?.username])

  // The session data has been fetched, fetch landlords next
  useEffect(() => {
    isAuthenticated && !waitingForGroups && queryCustomers.refetch()
  }, [isAuthenticated, waitingForGroups])

  // The places are fetched when one of customer has been set to the state
  useEffect(() => {
    if (isAuthenticated && customer?.id) {
      // If logged user is tenant, get place via catalogues.
      if (customerType === 'T') {
        queryCatalogues.refetch()
      } else {
        queryPlaces.refetch()
      }
    }
  }, [customer?.id])

  return (
    <Box>
      <Authenticator
        initialState={signUp ? 'signUp' : 'signIn'}
        signUpAttributes={['given_name', 'family_name', 'email']}
      >
        {({ signOut, user }) => {
          return (
            <>
              {!isAuthenticated ||
              waitingForGroups ||
              waitingForSession ||
              waitingForCustomers ||
              waitingForPlaces ? (
                <MoovyProgress />
              ) : sessionQuery.isError ||
                queryPlaces.isError ||
                queryCustomers.isError ? (
                <MoovyErrorPage />
              ) : (
                children
              )}
            </>
          )
        }}
      </Authenticator>
    </Box>
  )
}

export default MoovyAuthenticator
