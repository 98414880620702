import React, { CSSProperties } from 'react'
import { Alert, AlertProps, AlertTitle } from '@mui/material'

type MoovyAlertProps = {
  title: string
  children?: React.ReactNode
  severity?: AlertProps['severity']
  style?: CSSProperties
}

export const MoovyAlert = ({
  title,
  children,
  severity = 'error',
  style
}: MoovyAlertProps) => {
  const isChildren = React.isValidElement(children)

  const RenderOnlyTitle = () => {
    return <b>{title}</b>
  }
  const RenderOnlyDescription = () => {
    return <>{children}</>
  }
  const RenderAll = () => {
    return (
      <div>
        <AlertTitle>{title}</AlertTitle>
        {children}
      </div>
    )
  }

  return (
    <Alert severity={severity} style={style}>
      {title && isChildren ? (
        <RenderAll />
      ) : title ? (
        <RenderOnlyTitle />
      ) : (
        <RenderOnlyDescription />
      )}
    </Alert>
  )
}
