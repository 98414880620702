import {
  EndRightDTO,
  RightDTO,
  RightLicensePlateChangeDTO,
  RightResponseDTO
} from './dto'

import { API_ROOT_URL } from '..'
import { client } from '../moovyAxiosClient'

const BASE_URL = `${API_ROOT_URL}/rights`

const getRightByRef = async (ref: string) => {
  const response = await client.get<RightDTO>(`${BASE_URL}/${ref}`)
  return response.data
}

const changeLicensePlate = (
  rightHolderRef: string,
  rightLicensePlateChange: RightLicensePlateChangeDTO
) => {
  return client.post<RightResponseDTO>(
    `${BASE_URL}/${rightHolderRef}/change-license-plate`,
    rightLicensePlateChange
  )
}

const endRight = (rightHolderRef: string, right: EndRightDTO) => {
  return client.post<RightResponseDTO>(
    `${BASE_URL}/${rightHolderRef}/end`,
    right
  )
}

const RightsAPI = {
  getRightByRef,
  changeLicensePlate,
  endRight
}

export default RightsAPI
